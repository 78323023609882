const links = [
  {
    label: 'Home',
    url: '/',
  },
  {
    label: 'Work',
    url: '/work',
  },
  {
    label: 'Contact',
    url: '/contact',
  },
];
export default links;

import {
  Home,
  Briefcase as Work,
  Envelope as Contact,
} from 'styled-icons/boxicons-regular';

const Icons = {
  Home,
  Work,
  Contact,
};

export default Icons;
